/**
* Generated automatically at built-time (2024-12-03T07:57:09.567Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "guide-utekok",templateKey: "sites/98-a55c0482-fc81-4f55-a13a-0fac4d7d2781"};